import { AxiosResponse } from 'axios';
import { appGasService } from 'config/services/appgas';
import { Order, Product } from 'types';

interface CreateOrderUseCaseProps {
  cpf?: string;
  name?: string;
  phone?: string;
  coupon: string;
  change?: string;
  lastName?: string;
  firstName?: string;
  paymentId: string;
  companyId: string;
  addressId: string;
  productIds: Product[];
  salesChannel: string;
  sourceIntegration: string;
  card?: {
    card_number: string;
    holder_name: string;
    expiration_month: string;
    expiration_year: string;
    security_code: string;
  };
  session_id?: string;
  brand_id?: string;
  giftcard?: string;
  email?: string;
  installments?: number;
}

export class CreateOrderUseCase {
  private api = appGasService;

  async handle(props: CreateOrderUseCaseProps) {
    const {
      addressId,
      companyId,
      coupon,
      paymentId,
      productIds,
      salesChannel,
      sourceIntegration,
      brand_id,
      card,
      change,
      cpf,
      firstName,
      lastName,
      name,
      phone,
      giftcard,
      email,
      session_id,
      installments
    } = props;

    const payload = {
      cpf,
      name,
      phone,
      coupon,
      change,
      last_name: lastName,
      first_name: firstName,
      address_id: addressId,
      payment_id: paymentId,
      company_id: companyId,
      product_ids: productIds,
      sales_channel: salesChannel,
      source_integration: sourceIntegration,
      card,
      brand_id: brand_id,
      giftcard,
      email,
      session_id,
      installments
    };

    const { data } = await this.api.post<unknown, AxiosResponse<Order>>(
      `/v3/integrations/orders`,
      payload
    );

    return data;
  }
}
