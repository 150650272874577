import axios from 'axios';
import { Product } from 'types';
import { appGasService } from 'config/services/appgas';
import { createAsyncThunk } from '@reduxjs/toolkit';

type Order = {
  cpf?: string;
  name?: string;
  phone?: string;
  coupon: string;
  change?: string;
  lastName?: string;
  firstName?: string;
  paymentId: string;
  companyId: string;
  addressId: string;
  productIds: Product[];
  salesChannel: string;
  sourceIntegration: string;
  card?: {
    card_number: string;
    holder_name: string;
    expiration_month: string;
    expiration_year: string;
    security_code: string;
  };
  brand_id?: string;
  email?: string;
  installments?: number | null;
};

export const createOrder = createAsyncThunk(
  'order/createOrder',
  async function (data: Order) {
    const {
      cpf,
      name,
      phone,
      change,
      coupon,
      companyId,
      addressId,
      paymentId,
      productIds,
      firstName,
      lastName,
      salesChannel,
      sourceIntegration,
      brand_id,
      card = {},
      email,
      installments
    } = data;

    try {
      const response = await appGasService.post(
        `/v3/integrations/orders
      `,
        {
          cpf,
          name,
          phone,
          coupon,
          change,
          last_name: lastName,
          first_name: firstName,
          address_id: addressId,
          payment_id: paymentId,
          company_id: companyId,
          product_ids: productIds,
          sales_channel: salesChannel,
          source_integration: sourceIntegration,
          card,
          brand_id: brand_id,
          email,
          installments
        }
      );

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message);
      }

      throw error;
    }
  }
);
