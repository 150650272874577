/* eslint-disable react-hooks/exhaustive-deps */
import {
  ButtonsBox,
  IconButton,
  ModalTitle,
  CancelButton,
  ConfirmButton,
  ModalSubTitle
} from './styles';

import * as Yup from 'yup';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomInput from 'components/CustomInput';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Stack,
  useTheme,
  Typography
} from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useCheckout } from 'views/Review/hooks/useCheckoutContext';
import { useContext, useEffect, useState } from 'react';
import ProcessingModal from './ProcessingModal';
import { useAppDispatch } from 'store';
import { createOrder } from 'store/order/orderThunk';
import { Order } from 'types';
import { toast } from 'react-toastify';
import { trackEvent } from 'config/services/adjustTracking';
import { toPattern } from 'vanilla-masker';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { isValidCPF } from 'helpers/validate-cpf';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { SourceIntegrationContext } from 'providers/source-integration-context/source-integration-context';
import { SummaryContext } from 'providers/summary-context/summary.context';
import { useAddresses } from 'hooks/useAddressesContext';
import { ResellerContext } from 'providers/reseller-context/reseller-context';
import { ChosenProductsContext } from 'providers/chosen-products-context/chosen-products-contex';
import { LeadDataContext } from 'providers/lead-data-context/lead-data.context';
import { OrderContext } from 'providers/order-context/order.context';
import { CreditCard } from '@mui/icons-material';
import { formatPrice } from 'helpers/formatPrice';

type ModalProps = {
  open: boolean;
  onClose: () => void;
};

const inAppFormSchema = Yup.object({
  cardholderName: Yup.string()
    .min(2, 'Nome deve ter pelo menos 2 caracteres')
    .required('Campo obrigatório'),
  securityCode: Yup.string()
    .length(3, 'CVV deve ter 3 digitos')
    .required('Campo obrigatório'),
  validate: Yup.string()
    .length(4, 'Validade deve ter mês/ano')
    .required('Campo obrigatório'),
  cardNumber: Yup.string()
    .transform((value) => value.replace(/[^\d]/g, ''))
    .length(16, 'Número do cartão deve ter 16 dígitos')
    .required('Campo obrigatório'),
  cpf: Yup.string().required(),
  email: Yup.string()
    .email('Este campo deve ser um e-mail válido')
    .required('Campo obrigatório')
}).required();

type InAppFormData = Yup.InferType<typeof inAppFormSchema>;

export default function InAppModal(props: ModalProps) {
  const dispatch = useAppDispatch();
  const [isOpenInstallments, setIsOpenInstallments] = useState<boolean>(false);

  const { paymentId } = useCheckout();
  const { integration } = useContext(SourceIntegrationContext);
  const sourceIntegration = integration.source;
  const theme = useTheme();

  const { onClose, open } = props;

  const [processingModalOpen, setProcessingModalOpen] = useState(false);

  const {
    validate,
    cardNumber,
    securityCode,
    cardholderName,
    cpf,
    email,
    installments,
    setInstallments,
    setEmail,
    setCpf,
    setValidate,
    setCardNumber,
    setSecurityCode,
    setCardholderName,
    setFormIsValid
  } = useCheckout();

  const {
    control,
    handleSubmit,
    formState: { isValid },
    watch,
    setValue,
    trigger
  } = useForm<InAppFormData>({
    defaultValues: {
      cardNumber: '',
      securityCode: '',
      validate: '',
      cardholderName: '',
      cpf: '',
      email: ''
    },
    mode: 'onChange',
    resolver: yupResolver(inAppFormSchema)
  });

  const { summaryData } = useContext(SummaryContext);
  const { setOrder } = useContext(OrderContext);

  const { addressDataInput } = useAddresses();

  const { reseller } = useContext(ResellerContext);

  const { chosenProducts } = useContext(ChosenProductsContext);

  const { leadData } = useContext(LeadDataContext);

  useEffect(() => {
    cardNumber && setValue('cardNumber', cardNumber);
    securityCode && setValue('securityCode', securityCode);
    validate && setValue('validate', '');
    setValidate('');
    cardholderName && setValue('cardholderName', cardholderName);
    cpf && setValue('cpf', cpf);
    if (!email && leadData?.email) {
      setEmail(leadData.email);
      setValue('email', leadData.email);
    } else {
      email && setValue('email', email);
    }

    if (
      cardNumber &&
      securityCode &&
      validate &&
      cardholderName &&
      cpf &&
      email
    ) {
      trigger();
    }
  }, []);

  async function handleProccessOrder(data: InAppFormData) {
    if (!reseller || !addressDataInput) {
      return;
    }

    try {
      const { cardNumber, cardholderName, validate, securityCode, cpf } = data;

      setProcessingModalOpen(true);
      trackEvent({ eventToken: 'Solicitação em Andamento', sourceIntegration });

      const newOrder = (await dispatch(
        createOrder({
          cpf,
          paymentId,
          sourceIntegration,
          addressId: addressDataInput.id,
          companyId: reseller.id,
          salesChannel: 'compraweb',
          name: leadData.name,
          phone: leadData.phone,
          productIds: chosenProducts.map((product) => {
            return {
              ...product,
              brand_id: product.brand_id || reseller.brand_id
            };
          }),
          coupon: summaryData?.coupon || '',
          card: {
            card_number: cardNumber,
            holder_name: cardholderName,
            expiration_month: validate.slice(0, 2),
            expiration_year: validate.slice(2, 4),
            security_code: securityCode
          },
          brand_id: reseller.brand_id,
          email,
          installments: installments
        })
      ).unwrap()) as Order;

      const { status, payment_failure_reason } = newOrder;

      if (status === 'waiting') return setOrder(newOrder);

      const message =
        payment_failure_reason || 'Ocorreu um erro ao realizar o pagamento.';

      toast.error(message, {
        position: 'bottom-center',
        autoClose: 5000,
        draggable: true,
        closeOnClick: true,
        pauseOnHover: true,
        hideProgressBar: false
      });

      setProcessingModalOpen(false);

      return;
    } catch (error) {
      const typedError = error as Error;

      setProcessingModalOpen(false);
      trackEvent({ eventToken: 'Pagamento não aprovado', sourceIntegration });
      trackEvent({ eventToken: 'Crédito Não Aprovado', sourceIntegration });

      toast.error(
        typedError?.message || 'Ocorreu um erro ao tentar criar o pedido.',
        {
          position: 'bottom-center',
          autoClose: 5000,
          draggable: true,
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: false
        }
      );
    }
  }

  const watchCpf = watch('cpf');

  function formatCPF(value: string) {
    if (value) {
      return toPattern(value, '999.999.999-99');
    }

    return value;
  }

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth={true}
        onClose={onClose}
        sx={{ padding: '24px' }}
      >
        {isOpenInstallments ? (
          <>
            <DialogTitle>
              <ModalTitle>Pagamento parcelado</ModalTitle>

              <ModalSubTitle>
                Selecione abaixo o parcelamento desejado:
              </ModalSubTitle>

              <IconButton aria-label="close-modal" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent>
              <Stack sx={{ gap: '16px' }}>
                <Stack
                  sx={{
                    width: '100%',
                    paddingX: '20px',
                    backgroundColor: theme.palette.grey[100],
                    border: `solid 1px ${theme.palette.grey[300]}`,
                    borderRadius: '6px',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }}
                  onClick={() => setIsOpenInstallments(false)}
                >
                  <Stack
                    direction={'row'}
                    gap={'8px'}
                    alignItems={'center'}
                    paddingY={'6px'}
                  >
                    <CreditCard
                      sx={{
                        color: theme.palette.primary.dark,
                        fontSize: '18px'
                      }}
                    />
                    <Typography
                      fontWeight={'600'}
                      fontSize={'12px'}
                      lineHeight={'15px'}
                      color={theme.palette.grey[800]}
                    >
                      *** {cardNumber.split(' ')[3]}
                    </Typography>
                  </Stack>
                  <Typography>
                    {formatPrice(summaryData?.total || 0)}
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    border: `solid 1px ${theme.palette.grey[300]}`,
                    borderRadius: '8px'
                  }}
                >
                  {summaryData?.installment_interest_rates?.map(
                    (item, index, array) => (
                      <Stack
                        key={index}
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        p={'16px'}
                        bgcolor={
                          installments == item.installments
                            ? '#E5F5FF'
                            : undefined
                        }
                        borderBottom={
                          index !== array.length - 1
                            ? `solid 1px ${theme.palette.grey[300]}`
                            : 'none'
                        }
                        border={
                          installments == item.installments
                            ? `solid 1px ${theme.palette.primary.dark}`
                            : undefined
                        }
                        onClick={() => {
                          setInstallments(item.installments);
                        }}
                      >
                        <Stack
                          direction={'row'}
                          alignItems={'center'}
                          gap={'5px'}
                        >
                          <Typography fontWeight={'700'}>
                            {item.installments}x
                          </Typography>
                          <Typography fontWeight={'400'}>
                            {formatPrice(item.installment_value)}
                          </Typography>
                        </Stack>
                        <Stack>
                          <Typography
                            fontWeight={'400'}
                            color={
                              item.total == 'Sem juros'
                                ? theme.palette.success.main
                                : undefined
                            }
                          >
                            {parseInt(item.total.toString()) > 0
                              ? formatPrice(parseInt(item.total.toString()))
                              : item.total}
                          </Typography>
                        </Stack>
                      </Stack>
                    )
                  )}
                </Stack>
              </Stack>
              <ButtonsBox>
                <CancelButton variant="text" onClick={() => onClose()}>
                  Cancelar
                </CancelButton>
                <ConfirmButton
                  onClick={() => {
                    onClose();
                    setFormIsValid(true);
                  }}
                  disabled={!installments}
                  variant="contained"
                >
                  Comprar
                </ConfirmButton>
              </ButtonsBox>
            </DialogContent>
          </>
        ) : (
          <>
            <DialogTitle>
              <ModalTitle>Dados necessários</ModalTitle>

              <ModalSubTitle>
                Precisamos dos seguintes dados de seu cartão
              </ModalSubTitle>

              <IconButton aria-label="close-modal" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent>
              <form onSubmit={handleSubmit(handleProccessOrder)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={8}>
                    <Controller
                      name="cardNumber"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          {...field}
                          name="cardNumber"
                          label="Número do cartão"
                          value={cardNumber}
                          error={!!error}
                          helperText={error?.message}
                          icon={<CreditCardIcon color="primary" />}
                          onChange={(input) => {
                            const withoutMask = '9999999999999999';

                            const value = toPattern(
                              input.target.value,
                              withoutMask
                            );

                            if (value.length <= withoutMask.length) {
                              const inputReplaced = {
                                ...input,
                                target: {
                                  value
                                }
                              };

                              field.onChange(inputReplaced);
                              setCardNumber(
                                toPattern(value, '9999 9999 9999 9999')
                              );
                            }
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Controller
                      name="securityCode"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          {...field}
                          label="CVV"
                          error={!!error}
                          name="securityCode"
                          value={securityCode}
                          helperText={error?.message}
                          icon={<HttpsOutlinedIcon color="primary" />}
                          onChange={(input) => {
                            const value = toPattern(input.target.value, '999');

                            if (value.length <= 3) {
                              const inputReplaced = {
                                ...input,
                                target: {
                                  value
                                }
                              };

                              field.onChange(inputReplaced);
                              setSecurityCode(
                                toPattern(input.target.value, '999')
                              );
                            }
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Controller
                      name="validate"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          {...field}
                          error={!!error}
                          name="validate"
                          label="Mês e ano de validade"
                          value={validate}
                          helperText={error?.message}
                          icon={<DateRangeOutlinedIcon color="primary" />}
                          onChange={(input) => {
                            const value = toPattern(input.target.value, '9999');

                            if (value.length <= 4) {
                              const inputReplaced = {
                                ...input,
                                target: {
                                  value
                                }
                              };

                              field.onChange(inputReplaced);
                              setValidate(toPattern(value, '99/99'));
                            }
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item md={6}>
                    <Controller
                      name="cpf"
                      control={control}
                      render={({ field }) => (
                        <CustomInput
                          {...field}
                          name="change"
                          label="CPF"
                          value={formatCPF(cpf || '')}
                          icon={<AccountCircleOutlinedIcon color="primary" />}
                          onChange={(input) => {
                            field.onChange(input);
                            setCpf(formatCPF(input.target.value));
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Controller
                      name="cardholderName"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          {...field}
                          name="cardholderName"
                          label="Nome impresso no cartão"
                          value={cardholderName}
                          error={!!error}
                          helperText={error?.message}
                          icon={<PersonOutlineOutlinedIcon color="primary" />}
                          onChange={(input) => {
                            const value = input.target.value;
                            const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z_ ]*$/;

                            if (
                              value !== '' &&
                              !ALPHA_NUMERIC_DASH_REGEX.test(value)
                            ) {
                              return;
                            }

                            field.onChange(input);

                            setCardholderName(input.target.value);
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomInput
                          {...field}
                          name="email"
                          label="E-mail do titular"
                          value={email}
                          error={!!error}
                          helperText={error?.message}
                          icon={<PersonOutlineOutlinedIcon color="primary" />}
                          onChange={(input) => {
                            field.onChange(input);
                            setEmail(input.target.value);
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <ButtonsBox>
                  <CancelButton variant="text" onClick={() => onClose()}>
                    Cancelar
                  </CancelButton>
                  <ConfirmButton
                    onClick={() => {
                      // onClose();
                      // setFormIsValid(true);
                      setIsOpenInstallments(true);
                    }}
                    disabled={!isValid || !isValidCPF(watchCpf)}
                    variant="contained"
                  >
                    Confirmar
                  </ConfirmButton>
                </ButtonsBox>
              </form>
            </DialogContent>
          </>
        )}
      </Dialog>

      <ProcessingModal
        open={processingModalOpen}
        onClose={() => setProcessingModalOpen(false)}
      />
    </>
  );
}
